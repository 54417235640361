import { cloneDeep } from 'lodash';

const InformationDTO = {
  userTypeId: 'required',
  roleName: 'required',
  description: '',
};

const RoleSchema = {
  information: {
    entity: 'role/information',
    fields: {
      ...InformationDTO,
    },
  },
  others: {
    entity: 'role',
    fields: {
      clinicTypes: '',
    },
  },
  _serverSideValidators: {
    roleName: {
      fieldName: 'roleName',
      errorType: 'unique',
      functionName: 'roleNameCheckUnique',
      params: {},
    },
  },
};

const prepareRoleSchema = (roleSchema, _mode, params = {}) => {
  const schema = cloneDeep(roleSchema);

  schema._serverSideValidators.roleName.params = {
    roleId: params ? params.roleId : '',
  };
  if (params.requireClinicTypes) {
    schema.others.clinicTypes = 'requiredArray';
  }
  // default add mode
  return schema;
};

export const getRoleSchema = (mode, params) => {
  return prepareRoleSchema(RoleSchema, mode, params);
};
