<template>
  <lims-block>
    <h4 class="title" slot="blockTitle">{{ $t(`pages/Account/Role/Forms/RolePermissions/blockTitle`) }}</h4>
    <div class="md-layout" slot="blockContent">
      <div class="lims-form-row feature-group-permissions" v-if="featurePermissions.length > 0">
        <feature-group-permissions
          v-for="featureGroupData in featurePermissions"
          :permissionValues="permissionValues"
          :featureGroupData="featureGroupData"
          :key="`feature-group-permissions-${featureGroupData.featureGroupId}`"
          :disabled="disabled"
          :disabled-anonymise-information-request-checkbox="disabledAnonymiseInformationRequestCheckbox"
        ></feature-group-permissions>
      </div>
    </div>
  </lims-block>
</template>

<script>
import FeatureGroupPermissions from '../Components/FeatureGroupPermissions.vue';
import RoleMixins from '@/pages/Account/Role/role.mixins';

export default {
  mixins: [RoleMixins],
  created: function () {
    this.permissionValues = this.value;
  },
  mounted() {
    this.permissionValues = this.value;
  },
  components: {
    FeatureGroupPermissions,
  },
  props: {
    featurePermissions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    disabledAnonymiseInformationRequestCheckbox: {
      type: Boolean,
    },
  },
  name: 'LimsRolePermissions',
  watch: {
    permissionValues: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      permissionValues: null,
    };
  },
  methods: {
    toggleClass(evt) {
      evt.target.classList.toggle('closed');
    },
  },
};
</script>

<style lang="scss" scoped>
.role-permissions-tbl {
  margin: 0px;
  .feature-actions {
    font-size: 14px;
  }
}
.lims-collapsed-icon {
  background-color: black !important;
  .md-icon {
    transform: rotate(180deg);
  }
}
.lims-collapsed-icon .md-icon.closed {
  transform: rotate(0deg);
}
.feature-group-permissions {
  width: 100%;
}
</style>
