<template>
  <div class="role-permissions-collapse-wrapper">
    <md-radio
      class="deselect-btn"
      v-model="deselectAll"
      :value="deselectAllValue"
      :disabled="disabled"
      @change="onDeselectAll(featureGroupData.features)"
    >
      {{ $t('pages/Account/Role/Components/FeatureGroupPermissions/deselectAll') }}
    </md-radio>
    <collapse :wrapperClass="'role-permissions-collapse'" :collapse="featureGroupName" icon="keyboard_arrow_down">
      <template slot="md-collapse-pane-1">
        <md-table class="custom-tbl role-permissions-tbl" md-card>
          <md-table-row class="feature-actions">
            <md-table-head style="width: 50%">{{
              $t('pages/Account/Role/Components/FeatureGroupPermissions/name')
            }}</md-table-head>
            <md-table-head
              v-for="label in permissionTypeLabels"
              :key="'permissionTypeLabel_' + featureGroupId + '_' + label.permissionType"
              class="th-center"
              >{{ translatePermissionType(label.permissionName) }}</md-table-head
            >
          </md-table-row>
          <md-table-row v-for="feature in features" :key="'permission_feature_' + feature.featurePermissionId">
            <md-table-cell>{{
              translatePermissionFeatureName(featureGroupCodeName, feature.featureCodeName)
            }}</md-table-cell>
            <md-table-cell
              v-for="(label, idx) in permissionTypeLabels"
              :key="'permission_' + feature.featurePermissionId + '_' + idx"
              class="th-center"
            >
              <md-checkbox
                v-if="values && isPermissionTypeExists({ feature, permissionType: label.permissionType })"
                class="lims-checkbox"
                :disabled="
                  disabled ||
                  (feature.featureCodeName === 'Case_AnonymiseInformation-Request' &&
                    disabledAnonymiseInformationRequestCheckbox)
                "
                @change="
                  onClickCheckbox({
                    featurePermissionId: feature.featurePermissionId,
                    permissionType: label.permissionType,
                  })
                "
                v-model="values[feature.featurePermissionId][label.permissionType]"
              ></md-checkbox>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </template>
    </collapse>
  </div>
</template>

<script>
import { RolePermissionsHelper } from '@/core/helpers';
import { Collapse } from '@/components';
export default {
  name: 'LimsWebFeatureGroupPermissions',
  props: {
    featureGroupData: {
      type: Object,
      require: true,
    },
    permissionValues: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    disabledAnonymiseInformationRequestCheckbox: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  components: {
    Collapse,
  },
  created() {
    this.values = this.permissionValues;
  },
  mounted() {
    this.values = this.permissionValues;
  },
  computed: {
    featureGroupId() {
      return this.featureGroupData.featureGroupId;
    },
    featureGroupCodeName() {
      return this.featureGroupData.featureGroupCodeName;
    },
    featureGroupName() {
      const key =
        'pages/Account/Role/Components/FeatureGroupPermissions/featureGroup/featureGroupCodeName/' +
        this.featureGroupData.featureGroupCodeName;
      return [this.$translate(key)];
    },
    features() {
      return this.featureGroupData.features;
    },
    permissionTypeLabels() {
      const permissionTypeLabels = [...this.featureGroupData.permissTypeLabels];
      return permissionTypeLabels.reverse();
    },
  },
  data() {
    return {
      values: null,
      deselectAll: false,
      deselectAllValue: false,
    };
  },
  watch: {
    values: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val);
        if (this.featureGroupData) {
          let valueCheckArr = [];
          this.featureGroupData.features.forEach((f) => {
            Object.keys(this.values[f.featurePermissionId]).forEach((item) => {
              valueCheckArr.push(this.values[f.featurePermissionId][item]);
            });
          });
          if (valueCheckArr.includes(true)) {
            this.deselectAllValue = true;
          } else {
            this.deselectAllValue = false;
          }
        }
      },
    },
  },
  methods: {
    onDeselectAll(feature) {
      this.deselectAll = false;
      if (this.deselectAllValue) {
        feature.forEach((f) => {
          Object.keys(this.values[f.featurePermissionId]).forEach((item) => {
            this.values[f.featurePermissionId][item] = false;
          });
        });
      }
    },
    isPermissionTypeExists({ feature, permissionType }) {
      return RolePermissionsHelper.isPermissionTypeExists({ feature, permissionType });
    },
    onClickCheckbox({ featurePermissionId, permissionType }) {
      // eslint-disable-next-line security/detect-object-injection
      const hasAccess = this.values[featurePermissionId][permissionType];
      RolePermissionsHelper.permissionHierarchyFilter({
        permissions: this.values,
        labels: this.featureGroupData.permissTypeLabels,
        featurePermissionId,
        permissionType,
        hasAccess,
      });
    },
    translatePermissionFeatureName(featureGroupCodeName, featureCodeName) {
      return this.$translate(
        'pages/Account/Role/Components/FeatureGroupPermissions/' +
          featureGroupCodeName.replace(/\s/g, '') +
          '/featureName/' +
          featureCodeName.replace(/\s/g, ''),
      );
    },
    translatePermissionType(permissionName) {
      return this.$translate(`pages/Account/Role/Components/FeatureGroupPermissions/${permissionName}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.lims-collapsed-icon {
  background-color: black !important;
  .md-icon {
    transform: rotate(180deg);
  }
}
.lims-collapsed-icon .md-icon.closed {
  transform: rotate(0deg);
}
.role-permissions-collapse-wrapper {
  position: relative;
  .deselect-btn {
    position: absolute;
    right: 25px;
    top: 12px;
    z-index: 99;
  }
}
</style>
