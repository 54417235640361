<template>
  <lims-block>
    <h4 class="title" slot="blockTitle">{{ $t('pages/Account/Role/Forms/RoleNotification/blockTitle') }}</h4>
    <div class="md-layout" slot="blockContent">
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head></md-table-head>
            <md-table-head class="th-center">
              <md-checkbox
                :disabled="disabled"
                v-model="selectAll"
                :class="disabled ? 'disabled checkbox-all' : 'checkbox-all'"
              >
                {{ $t('pages/Account/Role/Forms/RoleNotification/enableLabel') }}
                <md-tooltip
                  v-if="!disabled"
                  class="tooltip-selectAll"
                  v-html="$t('global/tooltip/selectAll')"
                ></md-tooltip>
              </md-checkbox>
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, name) in value" :key="name">
            <md-table-cell>{{
              item.notificationDescription || item.notificationSetting.notificationDescription
            }}</md-table-cell>
            <md-table-cell class="cell-action">
              <md-checkbox :disabled="disabled" v-model="item.isEnabled" class="lims-checkbox"></md-checkbox>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>

<script>
export default {
  created: function () {
    this.notificationValues = this.value;
  },
  props: {
    value: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  name: 'LimsRolenotifications',
  computed: {
    selectAll: {
      set(selected) {
        if (this.value) {
          this.value.forEach(function (val) {
            val.isEnabled = selected;
          });
        }
      },
      get() {
        if (this.value) {
          return this.value.every(function (val) {
            return val.isEnabled;
          });
        }
        return null;
      },
    },
  },
  watch: {
    notificationValues: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      notificationValues: [],
    };
  },
};
</script>

<style lang="scss" scoped></style>
