<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <modal-confirm-generic
        ref="modalEmptyPermission"
        :title="$t('components/lims/modals/ModalConfirmation.title')"
        :description="$t('pages/Account/Role/Forms/RoleForm/modalEmptyPermission/description')"
        @onConfirm="onEmptyPermissionConfirm"
      >
      </modal-confirm-generic>
      <!-- Begin information -->
      <lims-block class="information-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/Account/Role/Forms/RoleForm/information/blockTitle') }}</h4>
        <modal-disable-role
          ref="disableRole"
          slot="blockTitle"
          @onCancel="$onCancelDisableRole"
          @onDisable="$onConfirmDisableRole(formData.roleId, formData.information.roleName)"
        ></modal-disable-role>
        <modal-delete-role
          ref="deleteRole"
          slot="blockTitle"
          @onCancel="$onCancelDeleteRole"
          @onDelete="$onConfirmDeleteRole(formData.roleId, formData.information.roleName)"
        ></modal-delete-role>
        <modal-show-list-user-in-role-form ref="showUserList" slot="blockTitle" :list-user="userList">
        </modal-show-list-user-in-role-form>
        <div slot="blockContent">
          <LimsFormEditButton class="edit-btn" v-if="isViewMode && $canEdit()" :editUrl="$editUrl" />
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-50">
              <lims-field :model="formData.information" :schema="roleSchema.information" field="roleName">
                <md-input
                  :disabled="isViewMode"
                  slot="field"
                  v-model="formData.information.roleName"
                  @change="$onServerSideValidation($event, roleSchema._serverSideValidators.roleName)"
                  type="text"
                  maxlength="200"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field
                :model="formData.information"
                :schema="roleSchema.information"
                field="description"
                class="textarea-field"
              >
                <md-textarea
                  :disabled="isViewMode"
                  slot="field"
                  v-model="formData.information.description"
                  type="text"
                  maxlength="1000"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="roleSchema.information" field="userTypeId">
                <v-select disabled slot="field" :options="userTypesList" v-model="formData.information.userTypeId">
                  <template #option="{ label }">
                    {{ $t(userTypesList.find((o) => o.value == label).label) }}
                  </template>
                  <template #selected-option="{ label }">
                    {{ $t(userTypesList.find((o) => o.value == label).label) }}
                  </template>
                </v-select>
              </lims-field>
            </div>
            <div v-if="isViewMode || isEditMode" class="md-layout-item md-size-15 md-small-size-100"></div>
            <div v-if="isViewMode || isEditMode" class="md-layout-item md-size-10">
              <label>{{ $t('entities/role/information/users') }}</label>
              <div class="md-static-field">
                <md-field>
                  <a @click="showUserList">{{ formData.information.numOfUser }}</a>
                </md-field>
              </div>
            </div>
            <div v-if="isViewMode || isEditMode" class="md-layout-item md-size-10 md-small-size-100"></div>
            <div v-if="isViewMode || isEditMode" class="md-layout-item md-size-40 md-small-size-100">
              <label>{{ $t('entities/role/information/lastUpdated') }}</label>
              <div class="md-static-field">
                <md-field>
                  <md-input disabled :value="coverLastUpdated()" type="text"></md-input>
                </md-field>
              </div>
            </div>
          </div>
        </div>
      </lims-block>
      <!-- end information -->
      <!-- Begin clinic type block -->
      <lims-block v-if="isAdmin">
        <h4 class="title" slot="blockTitle">{{ $t('pages/Account/Role/Forms/RoleForm/clinicTypes/blockTitle') }}</h4>
        <div slot="blockContent">
          <ValidationProvider tag="div" v-slot="{ failed }">
            <role-clinic-types v-model="formData.clinicTypes" :viewMode="isViewMode" :validation="{ failed }">
            </role-clinic-types>
          </ValidationProvider>
        </div>
      </lims-block>
      <!-- End clinic type block -->
      <role-permissions
        :disabled="isViewMode"
        :featurePermissions="featurePermissions"
        :disabled-anonymise-information-request-checkbox="disabledAnonymiseInformationRequestCheckbox"
        v-model="formData.permissions"
      ></role-permissions>
      <!-- Begin personal information block -->
      <lims-block v-if="isAdminOrPathologist">
        <h4 class="title" slot="blockTitle">{{ $t('pages/Account/Role/Forms/RoleForm/personalInfo/blockTitle') }}</h4>
        <div class="md-layout" slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-checkbox
                v-model="formData.personalInfo.isAnonymisePatientData"
                :disabled="isViewMode || disabledPersonalInfoBlock"
                class="lims-checkbox"
                >{{ $t('entities/role/personalInfo/isAnonymisePatientData') }}</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-checkbox
                v-model="formData.personalInfo.isAutomaticallyApproveRequest"
                class="lims-checkbox"
                :disabled="isAutomaticallyApproveRequestDisabled || disabledPersonalInfoBlock"
              >
                {{ $t('entities/role/personalInfo/isAutomaticallyApproveRequest') }}
              </md-checkbox>
            </div>
          </div>
        </div>
      </lims-block>
      <!-- End personal information block -->
      <role-notifications :disabled="isViewMode" v-model="formData.notifications"></role-notifications>
      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel />
          <md-button
            v-if="isShowSaveButton()"
            @click="onSave()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="isViewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button v-if="isShowEnableButton()" @click="onEnable()" class="md-primary lims-form-button">
            {{ $t('global/button/button.enable') }}
          </md-button>
          <md-button v-if="isShowDisableButton()" @click="onDisable()" class="md-danger lims-form-button">
            {{ $t('global/button/button.disable') }}
          </md-button>
          <md-button v-if="isShowDeleteButton()" @click="onDelete()" class="md-danger lims-form-button">
            {{ $t('global/button/button.delete') }}
          </md-button>
        </div>
      </div>
      <!-- End action form -->
    </form>
  </ValidationObserver>
</template>

<script>
import { APP_ROUTES, FORM_MODES, PERMISSIONS_TYPE_ENUM, ROLE_STATUSES } from '@/core/constants';
import { FormMixins, TabMixins } from '@/core/mixins';
import RoleMixins from '@/pages/Account/Role/role.mixins';
import RoleNotifications from './RoleNotifications.vue';
import RolePermissions from './RolePermissions.vue';
import { RoleService, NotificationService } from '@/services';
import { getRoleSchema } from '@/schemas/roles.schema';
import { ModalDisableRole, ModalDeleteRole, ModalConfirmGeneric } from '@/components/Lims/modals';
import ModalShowListUserInRoleForm from '../Components/ModalShowListUserInRoleForm.vue';
import RoleClinicTypes from '../Components/RoleClinicTypes.vue';
import { DateTimeHelper, RolePermissionsHelper } from '@/core/helpers';

export default {
  name: 'LimsRoleform',
  mixins: [FormMixins, TabMixins, RoleMixins],
  components: {
    ModalShowListUserInRoleForm,
    ModalDeleteRole,
    ModalDisableRole,
    ModalConfirmGeneric,
    RoleNotifications,
    RolePermissions,
    RoleClinicTypes,
  },
  props: {
    userType: {
      type: Number,
      require: true,
    },
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    roleResource: {
      type: Object,
      require: false,
    },
    featurePermissions: {
      type: Array,
      require: true,
    },
  },
  created: function () {
    this.fetchData();
  },
  data() {
    return {
      userList: [],
      formData: {
        information: {},
        permissions: null,
        notifications: [],
        personalInfo: {
          isAnonymisePatientData: true,
          isAutomaticallyApproveRequest: false,
        },
        clinicTypes: [],
      },
      isSubmitted: false,
      disabledPersonalInfoBlock: false,
      disabledAnonymiseInformationRequestCheckbox: false,
    };
  },
  computed: {
    roleSchema: function () {
      const requireClinicTypes = this.userType === this.USER_TYPES().Administrator;
      return getRoleSchema(this.formMode, {
        ...this.roleResource,
        requireClinicTypes,
      });
    },
    userTypesList() {
      return this.USERTYPES_ENUM();
    },
    isAdmin() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isViewMode() {
      return this.formMode === FORM_MODES.VIEW;
    },
    isEditMode() {
      return this.formMode === FORM_MODES.EDIT;
    },
    isAddMode() {
      return this.formMode === FORM_MODES.ADD;
    },
    isAdminOrPathologist() {
      return [this.USER_TYPES().Administrator, this.USER_TYPES().Pathologist].includes(this.userType);
    },
    isEnable() {
      return this.formData.roleStatusId === ROLE_STATUSES.Enable;
    },
    isDisable() {
      return this.formData.roleStatusId === ROLE_STATUSES.Disable;
    },
    isAutomaticallyApproveRequestDisabled() {
      return this.formMode === FORM_MODES.VIEW || !this.formData.personalInfo.isAnonymisePatientData;
    },
  },
  watch: {
    'formData.personalInfo.isAnonymisePatientData'() {
      if (!this.formData.personalInfo.isAnonymisePatientData) {
        this.featurePermissions.forEach((fGroup) => {
          if (fGroup.featureGroupCodeName == 'Case') {
            const caseAnonymiseApprove = fGroup.features.find(
              (f) => f.featureCodeName == 'Case_AnonymiseInformation-Request',
            );
            if (this.formData.permissions[caseAnonymiseApprove.featurePermissionId]) {
              this.formData.permissions[caseAnonymiseApprove.featurePermissionId][PERMISSIONS_TYPE_ENUM.Update] = false;
            }
          }
        });
        this.disabledAnonymiseInformationRequestCheckbox = true;
      } else {
        this.disabledAnonymiseInformationRequestCheckbox = false;
      }
      this.formData.personalInfo.isAutomaticallyApproveRequest = false;
    },
    'formData.permissions': {
      handler(value) {
        let anonymisedInformationApproveValidate;

        this.featurePermissions.forEach((fGroup) => {
          if (fGroup.featureGroupCodeName == 'Case') {
            const caseAnonymiseApprove = fGroup.features.find(
              (f) => f.featureCodeName == 'Case_AnonymiseInformation-Approve',
            );
            anonymisedInformationApproveValidate = caseAnonymiseApprove
              ? value[caseAnonymiseApprove.featurePermissionId][PERMISSIONS_TYPE_ENUM.Validate]
              : null;
          }
        });

        if (anonymisedInformationApproveValidate) {
          this.formData.personalInfo.isAnonymisePatientData = false;
          this.formData.personalInfo.isAutomaticallyApproveRequest = false;
          this.disabledPersonalInfoBlock = true;
        } else {
          this.disabledPersonalInfoBlock = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      if (this.roleResource) {
        this.formData = this.roleResource;
        this.userList = await this.loadUserList();
        this.$setHeadingTitle(this.formData.information.roleName);
      } else {
        this.formData.information.userTypeId = this.userType;
        this.formData.permissions = RolePermissionsHelper.buildPermissionsModel(this.featurePermissions);
      }

      if (this.isAddMode) {
        this.formData.notifications = await this.loadNotificationsSettingAddMode(this.userType);
      } else {
        this.formData.notifications = await this.loadNotificationsSettingEditMode(this.formData.roleId);
      }
      // reset
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
    },
    async loadNotificationsSettingEditMode(roleId) {
      const { data, error } = await NotificationService.getNotificationSettingByRole(roleId);
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          return data;
        }
      }
    },
    async loadNotificationsSettingAddMode(userTypeId) {
      const { data, error } = await NotificationService.getNotificationsSettingsByUserType(userTypeId);
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          return data;
        }
      }
    },
    async loadUserList() {
      const res = await RoleService.getListUser(this.formData.roleId);
      if (res.err) {
        return this.$alertError(res.error);
      }
      return res.data;
    },
    async showUserList() {
      if (this.formData.information.numOfUser > 0) {
        this.$refs.showUserList.open();
      }
    },
    coverLastUpdated() {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      const lastUpdatedDate = DateTimeHelper.formatDateTimeGlobal(this.formData.information.lastUpdatedDate, {
        timezoneValue,
        dateTimeAppFormat,
      });
      return this.formData.information.lastUpdatedBy + ' ' + lastUpdatedDate;
    },
    roleFormDataCommon() {
      return {
        information: {
          roleName: this.formData.information.roleName,
          description: this.formData.information.description,
          userTypeId: this.formData.information.userTypeId,
        },
        permissions: RolePermissionsHelper.mappingDataPermissionForUpdate({
          featurePermissions: this.featurePermissions,
          formDataPermissions: this.formData.permissions,
        }),
        notificationSettings: this.formData.notifications.map((notification) => {
          return {
            ...notification,
            notificationSettingId: notification.notificationSettingId,
          };
        }),
      };
    },
    roleFormData(isAdminOrPathologist, formMode) {
      const roleFormData = this.roleFormDataCommon();
      if (isAdminOrPathologist) {
        roleFormData.personalInfo = {
          isAnonymisePatientData: this.formData.personalInfo.isAnonymisePatientData,
          isAutomaticallyApproveRequest: this.formData.personalInfo.isAutomaticallyApproveRequest,
        };
        roleFormData.clinicTypes = this.formData.clinicTypes;
        if (formMode !== FORM_MODES.ADD) {
          roleFormData.roleId = this.formData.roleId;
        }
      } else {
        if (formMode === FORM_MODES.EDIT) {
          roleFormData.roleId = this.formData.roleId;
          roleFormData.notificationSettings = this.formData.notifications.map((notification) => {
            return {
              ...notification,
              userRoleNotificationSettingId: notification.userRoleNotificationSettingId,
            };
          });
        }
      }
      return roleFormData;
    },
    createRole() {
      return RoleService.create(this.roleFormData(this.isAdminOrPathologist, this.formMode));
    },
    updateRole() {
      return RoleService.updateOne(this.roleFormData(this.isAdminOrPathologist, this.formMode));
    },
    onEmptyPermissionConfirm() {
      this.$refs.modalEmptyPermission.close();
      // trigger save
      this.save(true);
    },
    checkRequiredConfirmation() {
      return RolePermissionsHelper.isTheRoleBeingCreatedHasNoPermission({
        permissionModel: this.formData.permissions,
      });
    },
    async save(onConfirm = false) {
      try {
        const isValid = onConfirm ? true : await this.$refs.form.validate();
        if (isValid) {
          if (this.checkRequiredConfirmation() && !onConfirm) {
            this.$refs.modalEmptyPermission.open();
            return;
          }
          const res = this.formMode === FORM_MODES.ADD ? await this.createRole() : await this.updateRole();
          if (res.data.error) {
            if (res.data.error == 'RoleIsExisted') {
              return this.$alertError(
                this.$t(`pages/Account/Role/Forms/RoleForm/alert/RoleIsExisted`, {
                  roleName: this.formData.information.roleName,
                }),
              );
            }
            return this.$alertError(res.data.error + ' error');
          }
          this.$nextTick(function () {
            this.$resetChangeDetection();
            this.onRedirectToList();
          });
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      } catch (error) {
        this.$alertError(error);
      }
    },
    onRedirectToList() {
      this.$router.push(APP_ROUTES.ROLE).then(
        this.$alertSuccess(
          this.formMode === FORM_MODES.ADD
            ? this.$t(`pages/Account/Role/Forms/RoleForm/alert/created`, {
                roleName: this.formData.information.roleName,
              })
            : this.$t(`pages/Account/Role/Forms/RoleForm/alert/updated`, {
                roleName: this.formData.information.roleName,
              }),
        ),
      );
    },
    onSave() {
      this.isSubmitted = true;
      this.save();
    },
    isShowSaveButton() {
      if (this.formData.isStatic) {
        return false;
      }
      if (this.formMode === FORM_MODES.ADD) {
        return this.$isAuthorized(['Accounts_RoleManagement_Create']);
      } else {
        if (this.formMode === FORM_MODES.EDIT) {
          return (
            this.$isAuthorized(['Accounts_RoleManagement_Update']) &&
            this.formData.roleStatusId === ROLE_STATUSES.Enable
          );
        } else {
          return false;
        }
      }
    },
    isShowEnableButton() {
      if (this.formData.isStatic) {
        return false;
      }
      if (this.formMode === FORM_MODES.VIEW) {
        return (
          this.$isAuthorized(['Accounts_RoleManagement_Update']) && this.formData.roleStatusId === ROLE_STATUSES.Disable
        );
      } else {
        return false;
      }
    },
    isShowDisableButton() {
      if (this.formData.isStatic) {
        return false;
      }
      if (this.formMode === FORM_MODES.EDIT) {
        return (
          this.$isAuthorized(['Accounts_RoleManagement_Update']) && this.formData.roleStatusId === ROLE_STATUSES.Enable
        );
      } else {
        return false;
      }
    },
    isShowDeleteButton() {
      if (this.formData.isStatic) {
        return false;
      }
      if (this.formMode === FORM_MODES.VIEW) {
        return (
          this.$isAuthorized(['Accounts_RoleManagement_Delete']) &&
          this.formData.roleStatusId === ROLE_STATUSES.Disable &&
          this.formData.information.numOfUser === 0
        );
      } else {
        return false;
      }
    },
    onDisable() {
      if (this.formData.information.numberOfActiveUser > 0) {
        this.$alertError(
          this.$t('pages/Account/Role/Forms/RoleForm/alert/disableError', {
            roleName: this.formData.information.roleName,
          }),
        );
      } else {
        this.$onDisableRole();
      }
    },
    async onEnable() {
      const { err, data } = await RoleService.enable(this.formData.roleId);
      if (err) {
        this.$alertError(err);
      }
      if (data) {
        await this.$nextTick(function () {
          this.$router.push({ path: '/roles' });
        });
        this.$alertSuccess(
          this.$t('pages/Account/Role/Forms/RoleForm/alert/enabled', {
            roleName: this.formData.information.roleName,
          }),
        );
      }
    },
    onDelete() {
      this.$onDeleteRole();
    },
    onCancel() {
      this.$resetChangeDetection();
      setTimeout(() => {
        this.$router.back();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
