<template>
  <div>
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <label>{{ $t('pages/Account/Role/Components/RoleClinicTypes/labels/title') }}</label>
      </div>
    </div>
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <v-select
          :tabindex="1"
          tabenable="yes"
          slot="field"
          :placeholder="$t('pages/Account/Role/Components/RoleClinicTypes/fields/selectedOption/placeholder')"
          :options="availableOptions"
          :label="'fieldItemName'"
          v-model="formData.selectedOption"
          :disabled="isViewMode"
        >
          <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
          <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
        </v-select>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <md-button type="button" @click="add()" class="lims-form-button block01-btn md-primary" :disabled="isDisabled">
          {{ $t('pages/Account/Role/Components/RoleClinicTypes/actions/add') }}
        </md-button>
      </div>
    </div>

    <md-table class="custom-tbl" md-card>
      <md-table-row>
        <md-table-head width="75%">
          {{ $t('pages/Account/Role/Components/RoleClinicTypes/columns/name') }}
        </md-table-head>
        <md-table-head class="th-center cell-action">
          {{ $t('global/pages/list.actions') }}
        </md-table-head>
      </md-table-row>

      <md-table-row v-for="item in formData.selectedOptions" :key="`labList${item.fieldItemId}`">
        <md-table-cell>{{ item.fieldItemName }}</md-table-cell>
        <md-table-cell class="cell-action">
          <a @click="viewMode ? '' : del(item)"><md-icon>close</md-icon></a>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { DropdownService } from '@/services';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
export default {
  name: 'RoleClinicTypes',
  props: {
    viewMode: {
      type: Boolean,
      require: true,
    },
    value: {
      type: Array,
      require: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      formData: {
        selectedOption: null,
        selectedOptions: [],
      },
      options: [],
    };
  },
  watch: {
    'formData.selectedOptions': {
      deep: true,
      handler: function (value) {
        this.$emit('input', value);
      },
    },
    value: {
      deep: true,
      handler: function (val) {
        this.formData.selectedOptions = val;
      },
    },
  },
  async created() {
    this.formData.selectedOptions = this.value;
    this.options = await this.fetchOptions();
  },
  computed: {
    availableOptions() {
      return this.applyFilter(this.options, this.formData.selectedOptions);
    },
    isDisabled() {
      return this.viewMode || !this.formData.selectedOption;
    },
    isViewMode() {
      return this.viewMode;
    },
  },
  methods: {
    applyFilter(options, omitOptions) {
      // ignore selected options
      const omitValues = omitOptions.map((item) => item.fieldItemId);
      return options.filter((item) => !omitValues.includes(item.fieldItemId));
    },
    add() {
      this.formData.selectedOptions.push(this.formData.selectedOption);
      this.resetForm();
    },
    del(removedOption) {
      this.formData.selectedOptions = this.formData.selectedOptions.filter(
        (option) => option.fieldItemId != removedOption.fieldItemId,
      );
    },
    resetForm() {
      this.formData.selectedOption = null;
    },
    async fetchOptions() {
      const dropdownOptions = await DropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.CLINIC_TYPE]);
      return dropdownOptions[DROPDOWN_SHORT_NAME.CLINIC_TYPE];
    },
  },
};
</script>

<style lang="scss" scoped></style>
