<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <br />
    <div class="dialog-content list-user-height">
      <md-table class="table-striped table-hover lims-table list-user-table">
        <md-table-row>
          <md-table-head v-for="(column, index) in columnOfTable" :key="index">
            <div>
              {{ $t(column.name) }}
            </div>
          </md-table-head>
        </md-table-row>
        <md-table-row v-for="item in listUser" :key="item.userId">
          <md-table-cell>{{ item.firstname }}</md-table-cell>
          <md-table-cell>{{ item.lastname }}</md-table-cell>
          <md-table-cell>{{ item.status }}</md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';
export default {
  name: 'ModalShowListUserInRoleForm',
  mixins: [modalMixins],
  props: {
    listUser: {
      require: true,
    },
  },
  data() {
    return {
      columnOfTable: [
        {
          name: 'pages/Account/Role/Components/ModalShowListUserInRoleForm/table/listUser.firstName',
          key: 'firstName',
        },
        {
          name: 'pages/Account/Role/Components/ModalShowListUserInRoleForm/table/listUser.lastName',
          key: 'lastName',
        },
        {
          name: 'pages/Account/Role/Components/ModalShowListUserInRoleForm/table/listUser.status',
          key: 'status',
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.list-user-height {
  .md-content.md-table-content.md-scrollbar.md-theme-default {
    max-height: 500px;
  }
}
</style>
